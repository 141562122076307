import React from "react";
import classNames from "classnames";
import SmoothScroll from "components/elements/SmoothScroll";
import navlinks from "feed/navlinks";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        {navlinks.map((navlink) => {
          return (
            <li key={navlink.key}>
              <SmoothScroll to={navlink.to}>{navlink.name}</SmoothScroll>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default FooterNav;
