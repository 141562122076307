import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import team from "feed/team";
import ProfileCard from "./partials/ProfileCard";
import { CardGroup } from "react-bootstrap";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const TheTeam = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "team section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "team-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Meet the Team",
  };

  return (
    <section id="team" {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <CardGroup>
              {team.map((member) => {
                return (
                  <ProfileCard
                    key={member.key}
                    className={`tiles-item ${member.animationClass}`}
                    dataRevealDelay="200"
                    member={member}
                  />
                );
              })}
            </CardGroup>
          </div>
        </div>
      </div>
    </section>
  );
};

TheTeam.propTypes = propTypes;
TheTeam.defaultProps = defaultProps;

export default TheTeam;
