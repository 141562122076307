import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const VisionMission = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "visionmission section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "visionmission section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Our Vision & Mission",
  };

  return (
    <section id="visionMission" {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="visionmission-item-content">
                  <p className="text-sm mb-0">
                    To be the company leader in the Industry providing
                    efficient, dynamic and excellent logistic services to our
                    customers with a SMILE….
                  </p>
                </div>

                <div className="visionmission-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="text-color-high">Our Vision</span>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="visionmission-item-content">
                  <p className="text-sm mb-0">
                    Developing unmatched reliable services to our customers
                    while achieving our dreams through synergy
                  </p>
                </div>

                <div className="visionmission-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="text-color-high">Our Mission</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

VisionMission.propTypes = propTypes;
VisionMission.defaultProps = defaultProps;

export default VisionMission;
