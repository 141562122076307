import React from "react";
import { Card } from "react-bootstrap";

const ProfileCard = ({ member, className, dataRevealDelay }) => {
  return (
    <Card
      className={`p-3 py-4 m-3 g-5 d-flex justify-content-center ${className}`}
      data-reveal-delay={dataRevealDelay}
    >
      <Card.Body>
        <div className="center-content">
          <img
            src={member.image.src}
            alt={member.image.alt}
            width="200"
            className="rounded-circle"
          />
          <Card.Title>
            <h3 className="mt-2">{`${member.prefix}. ${member.name}`}</h3>
            <span className="mt-1 clearfix">{member.designation}</span>
          </Card.Title>

          <Card.Text>
            <small className="mt-4">{member.description}</small>
          </Card.Text>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProfileCard;
