import React from "react";
// import sections
import Hero from "../components/sections/Hero";
import Services from "../components/sections/Services";
import TheTeam from "../components/sections/TheTeam";
import AboutUs from "components/sections/AboutUs";
import ContactUs from "components/sections/ContactUs";
import VisionMission from "components/sections/VisionMission";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = () => {
  return (
    <>
      <Hero className="illustration-section-01" />
      <AboutUs bottomDivider />
      <Services topDivider bottomDivider />
      <VisionMission topDivider />
      <TheTeam topDivider />
      <ContactUs topDivider bottomDivider />

      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default Home;
