const team = [
  {
    key: "member1",
    animationClass: "reveal-from-right",
    image: {
      src: require("../assets/images/sinhasana/team/kamal.jpg"),
      alt: "",
    },
    prefix: "Mr",
    name: "M Z M Kamal",
    designation: "Managing Director",
    description:
      "A motivated and enthusiastic individual who counts over 18 years of experience in managerial capacity in different organizations of similar fields. His proven track record has immensely helped in preparing and implementing comprehensive business plans to facilitate the achievement",
  },
  {
    key: "member2",
    animationClass: "reveal-from-left",
    image: {
      src: require("../assets/images/sinhasana/team/nayomi.jpg"),
      alt: "",
    },
    prefix: "Ms",
    name: "T N Adirimanna",
    designation: "Director",
    description:
      "Nayomi has been associated with Sinhasana Holdings since the inception of the business and coordinates with stakeholders to manage the portfolio. Her past experience in similar fields has led the company to step forward. She is responsible in overall marketing and business promotions of the company.",
  },
];

export default team;
