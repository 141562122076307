const Joi = require('joi');

const validateContactUs = (name, email, contactNumber, message) => {

    const schemaForm = Joi.object({
        name: Joi.string().required().messages({
            "string.empty": "Please enter your full name",
        }),
        email: Joi.string().email({ tlds: { allow: false } }).required().messages({
            "string.empty": "Please enter an email",
            "string.email": "Please enter a valid email",
        }),
        contactNumber: Joi.string().pattern(/^(?:7|0|(?:94))[0-9]{9,10}$/).messages({
            "string.pattern.base": "Please enter a valid phone number",
        }),
        message: Joi.string().min(10).required().messages({
            "string.empty": "Please enter a message",
            "string.min": "Message is too short (at least 10 characters)",
        }),
    });

    let result = schemaForm.validate({
        name: name,
        email: email,
        contactNumber: contactNumber,
        message: message,
    });

    if (!result.error) {
        return [!result.error, ""];
    }
    else {
        return [!result.error, result.error["message"]];
    }

};

export { validateContactUs };
