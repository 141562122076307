const navlinks = [
  {
    key: "aboutUs",
    to: "about",
    name: "About Us",
  },
  {
    key: "sevices",
    to: "services",
    name: "Services",
  },
  {
    key: "theTeam",
    to: "team",
    name: "Meet The Team",
  },
  {
    key: "visionMission",
    to: "visionMission",
    name: "Vision & Mission",
  },
  {
    key: "contactUs",
    to: "contact",
    name: "Contact Us",
  },
];

export default navlinks;
