import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const AboutUs = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section id="about" {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <h1 className="center-content reveal-from-bottom">About Us</h1>
          <p className="ta-j reveal-from-bottom" data-reveal-delay="200">
            Sinhasana Holdings was originally started as a partnership business
            in 2018 to carry out import clearing services and untaken all
            logistics services related to cargo clearing. In year 2021 we
            started a company which is incorporated under the name of Sinhasana
            Holdings (Pvt) Ltd under the Company’s act with an aim of enhancing
            our business capacities and engage with importing Industrial
            Chemicals/Fertilizers/Agricultural Chemical/Fabric for bulk
            distribution. However, the partnership business is still in
            operations and continuing with providing solutions to both importers
            and exporters to clear cargos. Our services are customized according
            to your requirement and provide specialized services to you.
            <br />
            <br />
            Our relationship spreads over many freight representatives and
            related services, which will make a mold to provide a service more
            than what you expect. Further, we are equipped with a qualified,
            supportive and friendly team who are committed to deliver unmatched
            services to you.
            <br />
            <br />
            We are thankful to you for choosing Sinhasana Holdings (Pvt) Ltd as
            your clearing and freight solution provider and we look forward to
            join hands with you to start a long term business relationship with
            you.
          </p>
        </div>
      </div>
    </section>
  );
};

AboutUs.propTypes = propTypes;
AboutUs.defaultProps = defaultProps;

export default AboutUs;
