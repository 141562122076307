const services = [
  {
    key: "service1",
    image: {
      src: require("../assets/images/sinhasana/services/arrow-down-solid.svg"),
      alt: "Arrow Down",
    },
    content: {
      title: "Direct Import and Bulk Sale",
      description: [
        "Industrial Chemical",
        "Fertilizer and other Agricultural Chemicals",
        "Fabric",
      ],
    },
    delay: "200",
  },
  {
    key: "service2",
    image: {
      src: require("../assets/images/sinhasana/services/truck-fast-solid.svg"),
      alt: "Truck Fast",
    },
    content: {
      title: "Imports and Distributing services",
      description: [
        "Customs Clearance and Forwarding",
        "Project / break-bulk Cargo Handling",
        "Transportation",
      ],
    },
    delay: "600",
  },
  {
    key: "service3",
    image: {
      src: require("../assets/images/sinhasana/services/newspaper-solid.svg"),
      alt: "Newspaper",
    },
    content: {
      title: "Regulating Import License",
      description: ["Excise department", "NMRA", "Precursor Control"],
    },
    delay: "1000",
  },
  {
    key: "service4",
    image: {
      src: require("../assets/images/sinhasana/services/ship-solid.svg"),
      alt: "Ship",
    },
    content: {
      title: "Logistics Solutions",
      description: [],
    },
    delay: "1400",
  },
];

export default services;
