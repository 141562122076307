import { toast } from 'react-toastify';

const showSuccessNotification = (message) => {
    return toast.success(message);
};

const showInfoNotification = (message) => {
    return toast.info(message);
}

const showFailureNotification = (message) => {
    return toast.error(message);
};

const closeNotification = (id) => {
    toast.dismiss(id);
}

export { showSuccessNotification, showInfoNotification, showFailureNotification, closeNotification };
