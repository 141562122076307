import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Input from "components/elements/Input";
import Button from "components/elements/Button";
import { validateContactUs } from "utils/Validators";
import {
  showSuccessNotification,
  showFailureNotification,
  showInfoNotification,
  closeNotification,
} from "utils/Notifier";
import axios from "axios";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const ContactUs = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [message, setMessage] = useState("");

  const outerClasses = classNames(
    "section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    let vdResults = validateContactUs(name, email, contactNumber, message);

    if (!vdResults[0]) {
      showFailureNotification(vdResults[1]);
    } else {
      let notiWaitId = showInfoNotification("Please wait...");
      axios({
        url: "https://api.web3forms.com/submit",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: JSON.stringify({
          access_key: process.env.REACT_APP_EMAIL_WEB3FORMS_ACCESS_KEY,
          subject: "Contact Form from sinhasana.lk",
          from_name: name,
          replyto: email,
          email: email,
          contact_number: contactNumber,
          message: message,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            closeNotification(notiWaitId);
            showSuccessNotification("Successfully submitted!");

            setName("");
            setEmail("");
            setContactNumber("");
            setMessage("");
          } else {
            closeNotification(notiWaitId);
            showFailureNotification("Unexpected response: " + response);
          }
        })
        .catch((error) => {
          closeNotification(notiWaitId);
          showFailureNotification("Unexpected error: " + error);
        });
    }
  };

  const handleReset = (e) => {
    e.preventDefault();

    setName("");
    setEmail("");
    setContactNumber("");
    setMessage("");
  };

  return (
    <section id="contact" {...props} className={outerClasses}>
      <div className="container-xs">
        <div className={innerClasses}>
          <h1 className="center-content reveal-from-top">Contact Us</h1>

          <form>
            <fieldset>
              <div className="mb-16">
                <Input
                  type="text"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                  placeholder="Your full name here"
                  label="Full Name"
                />
              </div>
              <div className="mb-16">
                <Input
                  type="email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  placeholder="Email"
                  label="Email"
                />
              </div>
              <div className="mb-16">
                <Input
                  type="text"
                  value={contactNumber}
                  onChange={(event) => {
                    setContactNumber(event.target.value);
                  }}
                  placeholder="Contact Number"
                  label="Contact Number"
                />
              </div>
              <div className="mb-16">
                <Input
                  type="textarea"
                  value={message}
                  onChange={(event) => {
                    setMessage(event.target.value);
                  }}
                  placeholder="Please type in your message here."
                  label="Message"
                />
              </div>
              <div className="mt-24 center-content">
                <div className="button-group">
                  <Button color="primary" onClick={handleSubmit}>
                    Submit
                  </Button>
                  <Button color="primary" onClick={handleReset}>
                    Reset
                  </Button>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </section>
  );
};

ContactUs.propTypes = propTypes;
ContactUs.defaultProps = defaultProps;

export default ContactUs;
