import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import { Typewriter } from "react-simple-typewriter";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-1 mb-16 reveal-from-bottom">
              Welcome to
              <br />
              <span className="text-color-primary">
                Sinhasana Holdings (Pvt) Ltd
              </span>
            </h1>
            <h3 className="mb-16" data-reveal-delay="200">
              <span className="reveal-from-left">Art of earning through </span>
              <span className="text-color-secondary reveal-from-bottom">
                <Typewriter
                  words={["Customer Loyalty"]}
                  loop={1}
                  cursor
                  cursorStyle="_"
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </span>
            </h3>
            <div className="container-xs m-0">
              <p
                className="m-0 mb-32 ta-j reveal-from-bottom"
                data-reveal-delay="400"
              >
                Committed to provide efficient and excellent services to our
                loyal customers who place trust over us to manage their business
                volumes
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
